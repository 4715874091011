@import "variables";
@import "audio";
@import "contact";
@import "foto";
@import "index";
@import "audio";
@import "showreel";
@import "vita";
@import "background";

@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap");

@-webkit-keyframes fade {
  from {
    opacity: 0.1;
  }
  to {
    opacity: 1;
  }
}

@keyframes fade {
  from {
    opacity: 0.1;
  }
  to {
    opacity: 1;
  }
}

* {
  box-sizing: border-box;
}

::-moz-selection {
  /* Code for Firefox */
  color: var(--background-color);
  background: var(--text-color);
}

::selection {
  color: var(--background-color);
  background: var(--text-color);
}

body,
html {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
}

body {
  // background-color: var(--background-color;
  background-color: var(--background-color);
  font-family: var(--font), Arial, Helvetica, sans-serif !important;
  color: var(--text-color);
  font-weight: var(--font-weight-light);
  font-size: var(--font-size-base);
  -webkit-font-smoothing: antialiased;
}

.mobile {
  display: none;
}

h1,
h2 {
  font-size: var(--font-size-headline);
  font-weight: var(--font-weight-regular);
  text-transform: uppercase;
  line-height: 1;
}

h3 {
  font-size: var(--font-size);
  font-weight: var(--font-weight-regular);
  text-transform: uppercase;
  line-height: 1;
}

h4 {
  font-weight: var(--font-weight-regular);
}

a {
  text-decoration: none;
  color: inherit;
}

button {
  font-family: var(--font), Arial, Helvetica, sans-serif !important;
  letter-spacing: 0.075rem;
  font-weight: var(--font-weight-medium) !important;
  font-size: var(--font-size) !important;
}

strong {
  font-weight: 600;
}

.content-container {
  display: grid;
  width: 100vw;
  min-height: 100vh;
  grid-template-columns: 190px 1fr;
  grid-template-rows: clamp(5rem, 8vw, 8rem) 1fr;
  grid-template-areas:
    "nav headline"
    "nav content";
}

#sidebar {
  grid-area: nav;
  border-right: thin solid var(--markup-color);
  padding-left: var(--space-standard);
  letter-spacing: 0.05rem;
}

#sidebar ul {
  position: fixed;
}

#sidebar ul li.menu-button h3 {
  margin-bottom: 0.5rem;
  transition: letter-spacing 0.2s;
}

#sidebar ul li.menu-button h3:hover {
  letter-spacing: 0.1rem;
}

#sidebar ul li.menu-underline {
  height: 1px;
  background-color: var(--markup-color);
  width: 130%;
  margin-left: -2rem;
  position: absolute;
  left: -130%;
  transition: 0.2s;
}

.headline-wrapper {
  padding-left: var(--space-standard);
  grid-area: headline;
  border-bottom: thin solid var(--markup-color);
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: sticky;
  top: 0;
  left: 0;
  background-image: linear-gradient(
    to bottom,
    black,
    rgba(0, 0, 0, 0)
  ) !important;
  z-index: 997;
}

.headline-wrapper h1 {
  line-height: 0.8;
  /* margin: 0 0 4px 0; */
}

.transition {
  transition: transform 0.5s;
}

footer {
  border-top: thin solid var(--markup-color);
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 6rem;
  background-color: var(--background-color);
  z-index: 999;
}

footer a {
  height: 100%;
  display: flex;
  align-items: center;
}

footer h3 {
  margin: 0;
  padding-left: var(--space-standard);
  padding-right: var(--space-standard);
}

// mobile styles

#mobile-menu-btn {
  width: 50px;
  // padding-top: calc( var(--space-standard) * .45);
  margin-right: var(--space-standard);
  cursor: pointer;
  position: fixed;
  right: 0;
  top: var(--space-standard);
  z-index: 999;
}

#top-line,
#middle-line,
#bottom-line {
  width: 100%;
  height: 2px;
  background-color: var(--markup-color);
  margin-bottom: calc(var(--space-standard) * 0.45);
}
.cc-color-override--1762072787.cc-window {
  color: white;
  background-color: none;
  border: 1px solid white;
  border-radius: 50px;
  margin-bottom: var(--space-standard);
  width: 50vw;
}

.cookiebanner {
  position: fixed;
  left: 0px;
  right: 0px;
  height: auto;
  min-height: 21px;
  z-index: 255;
  background: rgb(0, 0, 0);
  color: rgb(221, 221, 221);
  line-height: 21px;
  padding: 5px 16px;
  font-family: arial, sans-serif;
  font-size: 14px;
  text-align: center;
  bottom: 0px;
  opacity: 1;
  display: flex;
  flex-direction: row-reverse;
  justify-content: center;
}

.cookiebanner-close {
  padding-left: calc(var(--space-standard) * .5);
}

@media screen and (max-width: 768px) {
  .mobile {
    display: block;
  }

  .open {
    display: flex !important;
  }

  .blur {
    filter: blur(10px);
    -webkit-filter: blur(10px);
  }

  #mobile-menu {
    width: 100vw;
    height: 100vh;
    position: fixed;
    z-index: 998;
    // background-color: var(--background-color);
    background-color: rgba($color: #000000, $alpha: 0.4);
    display: none;
    align-items: center;
  }

  #mobile-menu li:first-child {
    border-top: thin solid var(--markup-color);
  }

  #mobile-menu li {
    border-bottom: thin solid var(--markup-color);
    margin-bottom: calc(var(--space-standard) * 0.5);
    text-align: center;
    width: 100vw;
  }

  .content-container {
    grid-template-columns: 1fr;
    grid-template-rows: clamp(5rem, 8vw, 8rem) 1fr;
    grid-template-areas:
      "headline"
      "content";
  }

  #sidebar {
    display: none;
  }

  footer h3 {
    font-size: 12px;
  }
}
