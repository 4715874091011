#vita-wrapper {
  grid-area: content;
}

#vita-content {
  // background: linear-gradient(180deg, var(--background-color), transparent);
  margin-bottom: calc( var(--space-standard) - .9);
  padding: var(--space-standard);
  padding-bottom: 0;
  font-weight: var(--font-weight-light) !important;
  display: inline-block;
  // position: -webkit-sticky;
  // position: sticky;
  // top: 0;
  // right: 0;
  z-index: 1;
}

.platforms-btn:last-child {
  margin-right: 0;
  // margin-bottom: 0;
}

.platforms-btn {
  min-width: 8vw;
  margin-right: var(--space-standard);
  margin-bottom: var(--space-standard);
  padding: calc( var(--space-standard) * 0.5) var(--space-standard);
  border: none;
  background: var(--markup-color);
  color: vat(--background-color);
  cursor: pointer;
  text-transform: uppercase;
  border-radius: calc( var(--space-standard) * 2);
  border: thin solid var(--markup-color);
  opacity: 0;
  filter: blur(0.5rem);
  transition: all 0.3s;
}

.platforms-btn:hover {
  background: rgba(0, 0, 0, calc( var(--space-standard) * 0.06));
  color: var(--text-color);
}

#resume {
  font-size: var(--font-size);
  font-weight: var(--font-weight-thin);
  // margin-top: calc( var(--space-standard) * 0.5 );
  margin-left: var(--space-standard);
  margin-bottom: var(--space-standard);
}

#resume p:first-child {
  margin-top: 0;
}

#resume p {
  margin: calc( var(--space-standard) * 1.05) 0 calc( var(--space-standard) * .5) 0;
  padding-bottom: calc( var(--space-standard) * 0.6);
  border-bottom: thin solid var(--markup-color);
  letter-spacing: 0.05rem;
  transition: letter-spacing 0.2s;
}

#resume .head {
  cursor: pointer;
}

#resume ul .open {
  transform: translateX(0);
}

#resume .head:hover {
  letter-spacing: 0.1rem;
}

#resume p span {
  margin-right: 0.75rem;
  display: inline-block;
  transform: rotate(0deg);
  cursor: pointer;
  transition: transform 0.3s;
  -webkit-transform-origin: 40% 60%;
  -moz-transform-origin: 40% 60%;
  -o-transform-origin: 40% 60%;
  transform-origin: 40% 60%;
  width: 30px;
  height: 20px;
  z-index: 0;
}

#resume p span svg {
  position: absolute;
}

#resume p span svg circle,
#resume p span svg line {
  transition: 0.3s;
}

#resume p:hover span svg circle {
  // fill: var(--background-color) !important;
  fill: rgba($color: #000000, $alpha: 0) !important;
  stroke: var(--markup-color) !important;
  stroke-width: 0.1rem !important;
}

#resume p:hover span svg line {
  stroke: var(--markup-color) !important;
}

#resume ul {
  letter-spacing: 0.05rem;
  display: none;
  margin-left: 2.5rem;
}

#resume li {
  margin-bottom: 1rem;
}

#resume li h3 {
  border-bottom: thin solid var(--markup-color);
  padding-bottom: 1.5rem;
  margin-top: 3rem;
  margin-bottom: 0;
  border-top: thin solid var(--markup-color);
  padding-top: 1.25rem;
}

#resume li:first-child h3 {
  border-top: none;
  margin-top: -1rem;
}

#resume li h4 {
  margin-bottom: 0;
}

#resume .open {
  display: block !important;
}

@media screen and (max-width: 768px) {
  #vita-content {
    padding-bottom: 0.5rem;
  }

  #resume {
    margin-right: var(--space-standard);
  }

  #resume .open {
    margin-left: 0;
  }
}