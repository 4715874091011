:root {
  --background-color: black;
  --text-color: white;
  --markup-color: white;
  --font: Manrope;
  --font-size-base: 10px;
  --font-size: 1.5rem;
  --font-size-headline: clamp(2rem, 4vw, 4rem);
  --font-weight-light: 200;
  --font-weight-thin: 300;
  --font-weight-regular: 400;
  --font-weight-medium: 500;
  --space-standard: 2rem;
  --stroke-width: thin;
}

@media screen and (max-width: 768px) {
  :root {
    --font-size: 1rem;
    --space-standard: 1.5rem;
    --font-weight-thin: 400;
  }
}
