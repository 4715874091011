#full-video .headline-wrapper {
  background-image: none !important;
}

#myVideo {
  width: 100vw;
  height: 100vh;
  object-fit: cover;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  z-index: -1;
}

#myMobileVideo {
  display: none;
}


#showreel-content {
  position: absolute;
  bottom: 0;
  right:0;
  color: var(--markup-color);
  padding: 2rem;
  font-weight: 200 !important;
}

.showreel-controls {
  min-width: 8vw;
  font-size: var(--font-size);
  margin-left: var(--space-standard);
  padding: calc(var(--space-standard) / 2) var(--space-standard);
  border: none;
  background: var(--markup-color);
  color: var(--background-color);
  cursor: pointer;
  text-transform: uppercase;
  border-radius: 4vw;
  border: thin solid var(--markup-color);
  transition: 0.2s;
}

.showreel-controls:hover {
  background: var(--background-color);
  color: var(--markup-color);
}

@media screen and (max-width: 768px) {
  #full-video .headline-wrapper {
    background-image: linear-gradient(to bottom, black, rgba(0, 0, 0, 0)) !important;
  }

  #showreel-content {
    position: relative;
    padding: 0;
    height: 100%;
    display: flex;
  }

  #myVideo {
    display: none;
  }

  #myMobileVideo {
    display: block;
    width: 100%;
  }

  .showreel-controls {
    display: none;
  }
}

@media screen and (max-width: 425px) {
  

}