#foto-container .headline-wrapper, #foto-container #sidebar {
  background-image: none;
  background-color: var(--background-color) !important;
}

#foto-wrapper {
  grid-area: content;
  display: flex;
  overflow-y: scroll;
  flex-wrap: wrap;
}

#foto-wrapper span {
  display: none;
}

#foto-wrapper a {
  width: calc(100% / 4);
  min-height: 50%;
}

.gallery-item {
  background-repeat: no-repeat;
  background-size: cover;
  background-position-y: top;
  background-position-x: right;
  opacity: 0.8;
  max-width: 100%;
  height: 100%;
  transition: all 0.4s;
}

.gallery-item:hover {
  // background-position: center;
  background-color: transparent;
  opacity: 1;
}

// .light {
//   position: absolute;
//   top: calc(50% - 20px);
//   left: calc(50% - 20px);
//   width: 0;
//   height: 0;
//   border-radius: 50%;
//   background-color: transparent;
//   box-shadow: 1px 0px 100px 100px rgba(255, 255, 255, .25);
//   mix-blend-mode: soft-light;
//   z-index: 997;
// }

@media screen and (max-width: 1600px) {
  #foto-wrapper a {
    width: calc(100% / 3);
  }
  
}

@media screen and (max-width: 956px) {
  #foto-wrapper a {
    width: calc(100% / 2);
  }

  .gallery-item {
    opacity: 1;
  }
}
